exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-applications-js": () => import("./../../../src/pages/applications.js" /* webpackChunkName: "component---src-pages-applications-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inventory-js": () => import("./../../../src/pages/inventory.js" /* webpackChunkName: "component---src-pages-inventory-js" */),
  "component---src-pages-mines-and-processing-js": () => import("./../../../src/pages/mines-and-processing.js" /* webpackChunkName: "component---src-pages-mines-and-processing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-detail-js": () => import("./../../../src/pages/product-detail.js" /* webpackChunkName: "component---src-pages-product-detail-js" */),
  "component---src-pages-product-ferro-alloy-grade-js": () => import("./../../../src/pages/product/ferro-alloy-grade.js" /* webpackChunkName: "component---src-pages-product-ferro-alloy-grade-js" */),
  "component---src-pages-product-glassy-js": () => import("./../../../src/pages/product/glassy.js" /* webpackChunkName: "component---src-pages-product-glassy-js" */),
  "component---src-pages-product-granular-js": () => import("./../../../src/pages/product/granular.js" /* webpackChunkName: "component---src-pages-product-granular-js" */),
  "component---src-pages-product-others-js": () => import("./../../../src/pages/product/others.js" /* webpackChunkName: "component---src-pages-product-others-js" */),
  "component---src-pages-product-pegmatite-js": () => import("./../../../src/pages/product/pegmatite.js" /* webpackChunkName: "component---src-pages-product-pegmatite-js" */),
  "component---src-pages-product-quartzite-js": () => import("./../../../src/pages/product/quartzite.js" /* webpackChunkName: "component---src-pages-product-quartzite-js" */),
  "component---src-pages-product-semi-glassy-js": () => import("./../../../src/pages/product/semi-glassy.js" /* webpackChunkName: "component---src-pages-product-semi-glassy-js" */),
  "component---src-pages-product-semi-granular-js": () => import("./../../../src/pages/product/semi-granular.js" /* webpackChunkName: "component---src-pages-product-semi-granular-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */)
}

